import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import { MoreVert, Replay } from "@material-ui/icons";

import FinanceiroTicketModal from "../FinanceiroTicketModal";
import ContratoTicketModal from "../ContratoTicketModal";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import TicketOptionsMenu from "../TicketOptionsMenu";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles(theme => ({
	actionButtons: {
		marginRight: 6,
		flex: "none",
		alignSelf: "center",
		marginLeft: "auto",
		"& > *": {
			margin: theme.spacing(1),
		},
	},
}));



const TicketActionButtons = ({ ticket, handleClose, isMounted }) => {
	const [transferTicketModalOpen, setTransferTicketModalOpen] = useState(false);
	const [financeiroTicketModalOpen, setFinanceiroTicketModalOpen] = useState(false);
	const [contratoTicketModalOpen, setContratoTicketModalOpen] = useState(false);

	const classes = useStyles();
	const history = useHistory();
	const [anchorEl, setAnchorEl] = useState(null);
	const [loading, setLoading] = useState(false);
	const ticketOptionsMenuOpen = Boolean(anchorEl);
	const { user } = useContext(AuthContext);



	const handleOpenTicketOptionsMenu = e => {
		setAnchorEl(e.currentTarget);
	};

	const handleCloseTicketOptionsMenu = e => {
		setAnchorEl(null);
	};




	const handleUpdateTicketStatus = async (e, status, userId) => {
		setLoading(true);
		try {
			await api.put(`/tickets/${ticket.id}`, {
				status: status,
				userId: userId || null,
			});

			setLoading(false);
			if (status === "open") {
				history.push(`/tickets/${ticket.id}`);
			} else {
				history.push("/tickets");
			}
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
	};



	const handleCloseFinanceiroTicketModal = () => {
		setFinanceiroTicketModalOpen(false);
	};


	const handleCloseContratoTicketModal = () => {
		setContratoTicketModalOpen(false);
	};


	const handleOpenContratoModal = e => {
		setContratoTicketModalOpen(true);
		handleClose();
	};


	const handleOpenFinanceiroModal = e => {
		setFinanceiroTicketModalOpen(true);
		handleClose();
	};


	return (


		<div className={classes.actionButtons}>

			{ticket.status === "open" && !ticket.isGroup && (
				<>
					<ButtonWithSpinner
						loading={loading}
						size="small"
						variant="contained"
						color="primary"
						onClick={handleOpenFinanceiroModal}>
						{i18n.t("GERAR PIX")}
					</ButtonWithSpinner>

					<ButtonWithSpinner
						loading={loading}
						size="small"
						variant="contained"
						color="info"
						onClick={handleOpenContratoModal}>
						{i18n.t("GERAR CONTRATO")}
					</ButtonWithSpinner>



					<IconButton onClick={handleOpenTicketOptionsMenu}>
						<MoreVert />
					</IconButton>
					<TicketOptionsMenu
						ticket={ticket}
						anchorEl={anchorEl}
						menuOpen={ticketOptionsMenuOpen}
						handleClose={handleCloseTicketOptionsMenu}



					/>
					<FinanceiroTicketModal
						modalOpen={financeiroTicketModalOpen}
						onClose={handleCloseFinanceiroTicketModal}
						ticketid={ticket.id}
					/>


					<ContratoTicketModal
						modalOpen={contratoTicketModalOpen}
						onClose={handleCloseContratoTicketModal}
						ticketid={ticket.id}
					/>


				</>


			)}
		</div>
	);
};

export default TicketActionButtons;
